<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus
            :title="
              $t(
                'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.headline'
              )
            "
            :hide-plus="true"
          />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5">
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.summary.total'
            )
          "
          :value="indexMetaData.count.total"
          variant="gray"
        />
      </div>

      <FSTable
        :fst-id="`marketplacePurchaseIndex`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="getEndpoint"
        :qso="getEndpointOptions"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem
                  :text="`${item.buyer.first_name} ${item.buyer.last_name}`"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.buyer.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem :text="item.buyer.email" />
                <FSTableRowItem :text="item.transaction_time" :date="true" />
                <FSTableRowItem :text="item.order.number_of_vehicles_to_buy" />
                <FSTableRowItem
                  :text="
                    `${item.order.marketplace_fleet.fleet.country.currency_symbol}${item.order.order_amount}`
                  "
                />

                <FSTableRowItem>
                  <template v-if="item.payment_method">
                    <i :class="getPaymentCardIconClass(item.payment_method)" />
                    <span>&nbsp;&nbsp;</span>
                    {{
                      `${item.payment_method.brand}**${item.payment_method.detail}`
                    }}
                  </template>
                  <template v-else><span>--</span></template>
                </FSTableRowItem>
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item.status)"
                    :variant="getItemVariant(item.status)"
                    profile="payment"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <oto-eye-icon
                    v-tooltip.bottom="'View Invoice'"
                    @click="onInvoiceModalReq(item.order.id)"
                  />
                </FSTableRowItem> </FSTableRow
            ></template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="`${item.buyer.first_name} ${item.buyer.last_name}`"
                  :to="{
                    name: 'ViewBuyerProfile',
                    params: { id: item.buyer.id, redirectPath: path },
                  }"
                />
                <FSTableRowItem :text="item.order.number_of_vehicles_to_buy" />
                <FSTableRowItem
                  :text="
                    `${item.order.marketplace_fleet.fleet.country.currency_symbol}${item.order.order_amount}`
                  "
                />
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item.status)"
                    :variant="getItemVariant(item.status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.invoiceId'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.payerEmail'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.buyer.email || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.transactionTime'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.transaction_time,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.paymentMethod'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <template v-if="item.payment_method">
                        <i
                          :class="getPaymentCardIconClass(item.payment_method)"
                        />
                        <span>&nbsp;&nbsp;</span>
                        {{
                          `${item.payment_method.brand}**${item.payment_method.detail}`
                        }}
                      </template>
                      <template v-else><span>--</span></template>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.actions'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <oto-eye-icon
                        v-tooltip.bottom="'View Invoice'"
                        @click="onInvoiceModalReq(item.order.id)"
                      />
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
    <DynamicInvoiceModal
      :htmlString="invoiceHtmlString"
      :id="invoiceId"
      pdfName="marketplace-purchase"
      @send-email="sendInvoiceEmail"
    />
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
export default {
  name: 'ViewMarketplacePurchases',
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    DynamicInvoiceModal: () =>
      import('@/components/modals/DynamicInvoiceModal.vue'),
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    DateRangePicker,
  },
  data() {
    return {
      indexDataEndpoint: MarketplaceConfig.api.purchaseIndex(),
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      path: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.invoiceId'
          ),
          width: '5%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.payerName'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.payerEmail'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.transactionTime'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.noOfVehicle'
          ),
          width: '7%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.amount'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.paymentMethod'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.status'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.actions'
          ),
          width: '5%',
          sort: 'null',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.payerName'
          ),
          width: '30%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.noOfVehicle'
          ),
          width: '20%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.amount'
          ),
          width: '20%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.orders.details.marketplace.steps.purchase.table.columns.status'
          ),
          width: '20%',
          sort: 'null',
        },
      ],
      invoiceDetails: {},
      invoiceHtmlString: '',
      invoiceId: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    id() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.purchaseIndex(this.id)
    },

    getEndpointOptions() {
      return {
        prepend: '&',
        append: '',
      }
    },
  },
  async created() {
    this.path = this.$route.path
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'failed') return 'red'
      if (data === 'unpaid') return 'gray'
      return 'orange'
    },
    getItemStatus(data) {
      if (data === 'paid') return 'Paid'
      if (data === 'failed') return 'Failed'
      if (data === 'unpaid') return 'Unpaid'
      return data
    },
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      console.log('getPaymentCardIconClass', brand)
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
    async onInvoiceModalReq(id) {
      this.invoiceId = id
      this.$notify({
        group: 'generic',
        type: 'default',
        title: `Processing`,
        text: 'The action is being executed...',
      })

      await this.$http
        .get(MarketplaceConfig.api.getOrderEmailTemplate(id))
        .then((res) => {
          this.invoiceHtmlString = res.data
          this.$modal.show('dynamicInvoiceModal')
        })
        .catch((err) => {
          console.log('email-template-err', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },
    async sendInvoiceEmail(id) {
      const formData = new FormData()
      formData.append('order_id', id)
      await this.$http
        .post(MarketplaceConfig.api.sendPurchaseInvoice(), formData)
        .then(() => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `Invoice sent successfully`,
            },
            3000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response}`,
            },
            5000
          )
        })
    },
    // async sendInvoiceEmail(id) {
    //   await this.$http
    //     .post(useEndpoints.trip.sendEmail(id))
    //     .then((res) => {
    //       this.$notify(
    //         {
    //           group: 'generic',
    //           type: 'success',
    //           title: 'Success',
    //           text: res.data,
    //         },
    //         3000
    //       )
    //     })
    //     .catch((err) => {
    //       console.log('send-email-err', err)
    //       this.$notify({
    //         group: 'generic',
    //         type: 'error',
    //         title: `Server Error [${err.response.status}]`,
    //         text: 'Unable to process the request!',
    //       })
    //     })
    // },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
